<template>
  <main class="default-sea-index">
    <div class="sea-index">
      <div class="none"></div>
      <div class="index-apply">
        <div class="apply-box">
          <div class="apply-title">
            <h4 class="h-title">海葬报名</h4>
          </div>
          <div class="box-list">
            <div class="box-item service">
              <div class="apply">点击方向开始报名<i class="sea-iconfont">&#xe658;</i></div>
            </div>
            <div class="box-item dalian">
              <div class="name">大连方向</div>
              <div class="item-mask">
                <img @click="registration('大连')" src="../../assets/images/sea-burial/new-apply-btn.png" />
              </div>
            </div>
            <div class="box-item yingkou">
              <div class="name">营口方向</div>
              <div class="item-mask">
                <img @click="registration('营口')" src="../../assets/images/sea-burial/new-apply-btn.png" />
              </div>
            </div>
            <div class="box-item dandong">
              <div class="name">丹东方向</div>
              <div class="item-mask">
                <img @click="registration('丹东')" src="../../assets/images/sea-burial/new-apply-btn.png" />
              </div>
            </div>
            <div class="sign-up">
              <button class="mobile-up" type="button" @click="openSignUp('手机端报名流程')"><i class="sea-iconfont">&#xe676;</i><span>手机端报名流程示例</span></button>
              <button class="web-up" type="button" @click="openSignUp('pc端报名流程')"><i class="sea-iconfont">&#xe6fa;</i><span>网页端报名流程示例</span></button>
            </div>
          </div>
        </div>
      </div>

      <div class="sea-describe" v-if="seaList.length > 0" v-for="(sea, index) in seaList" :key="sea.id" :id="getId(sea.anchorId)">
        <div class="describe-box">
          <div class="box-swiper">
            <div class="swiper-container" :id="getId(sea.swiperId)">
              <div class="swiper-wrapper" v-if="sea.content_image_list">
                <div class="swiper-slide" v-for="(banner, index) in sea.content_image_list" :key="banner.id">
                  <img  :src="banner.url" />
                </div>
              </div>
              <div class="swiper-pagination"></div><!--分页器。如果放置在swiper-container外面，需要自定义样式。-->
            </div>
          </div>
          <div class="box-content">
            <div class="box-title">
              <h4>{{sea.title}}</h4>
            </div>
            <article class="box-article" v-html="sea.content"></article>
          </div>
        </div>
      </div>

    </div>
    <xgj-modal v-model="xgjShowModal" width="1140px" @close="close">
      <div class="popup-sea">
        <div class="popup-sea-close"><i class="sea-iconfont" @click="xgjShowModal=false">&#xe809;</i></div>
        <div class="popup-sea-article" v-if="processDetail" v-html="processDetail.content"></div>
        <div class="slide_tips">
          <i class="s_line"></i>
          <span class="txt">鼠标滑动</span>
        </div>
      </div>
    </xgj-modal>
    <div class="float-window" v-if="scroll">
      <div class="item" @click="scrollTop">
        <div class="iconfont sea-iconfont">&#xe636;</div>
        <div>前去报名</div>
      </div>
    </div>
  </main>
</template>

<script>
  import 'swiper/dist/css/swiper.css'
  import Swiper from 'swiper/dist/js/swiper.js'

  export default {
    name: 'sea-index',
    data() {
      return {
        xgjShowModal: false,
        seaList: [],
        processDetail: null,
        scroll: false
      }
    },
    created() {
      this.$route.query.selector ? this.scroll = true : this.scroll = false;
      this.getContentSeaName()
    },
    methods: {
      scrollTop() {
        window.scrollTo(0,0)
      },
      registration(periodSite) {
        this.$router.push({
          path: '/sea/sea-burial',
          query: {
            periodSite: periodSite
          }
        })
      },
      autoSwiper() {
        this.seaList.map(item => {
          if (item.content_image_list) {
            this.swiperNews = new Swiper(`#${item.swiperId}`, {
              autoplay: true,
              effect : 'fade',
              fadeEffect: {
                crossFade: true,
              },
              pagination: {
                el: '.swiper-pagination',
                clickable :true,
              },
              observeParents: false,   //注意这里！！
              observer: true, //也要注意这里！！
            });
          }
        })
      },
      async getContentSeaName() {
        const that = this;
        const loading = this.$loading({
          lock: true,
          text: '加载中',
          background: 'rgba(255, 255, 255, .8)'
        });
        let params = {
          partnerId: that.$store.state.user.partnerId,
          columnName: '海葬报名'
        };
        await that.$api.website.getContentSeaName(params)
          .then(dates => {
            var obj = [{"anchorId": "dalian", "swiperId": "dalian-banner"},
              {"anchorId": "yingkou", "swiperId": "yingkou-banner"},
              {"anchorId": "dandong", "swiperId": "dandong-banner"},
              {"anchorId": "daisa", "swiperId": "daisa-banner"}];
            that.seaList = dates.map((item, index) => {
              return {...item, ...obj[index]}
            });
            this.$nextTick(() => {
              if (this.$route.query.selector) {
                this.anchor(this.$route.query.selector);
              }
              this.autoSwiper()
            })
            loading.close()
          }).catch(err => {
            loading.close()
          })
      },
      getId(id) {
        return `${id}`
      },
      close() {
        this.xgjShowModal = false;
      },
      openSignUp(title) {
        this.xgjShowModal = true;
        this.$api.website.getServeProcess({partnerId: this.$store.state.user.partnerId, columnName: title})
          .then( detail => {
            this.processDetail = detail
          }).catch( err => {})
      },
      anchor(selector) {
        var anchor = this.$el.querySelector(`#${selector}`);
        document.documentElement.scrollTop = anchor.offsetTop
      }
    }
  }
</script>

<style lang="scss" scoped>
  .swiper-slide {
    position: relative;
    cursor: pointer;
    height: 350px;
    img {
      position: absolute;
      top:0;
      left:0;
      width: 560px;
      height:100%;
    }
  }
  @import "./sea-index.scss";
</style>
